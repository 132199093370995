import { graphql, PageProps } from 'gatsby';

import Layout from 'src/components/common/Layout';
import Meta from 'src/components/common/Meta';

export default function NotFoundPage({ data }: PageProps<Queries.NotFoundPageQuery>) {
  const siteTitle = data.site?.siteMetadata.title ?? '';

  return (
    <Layout>
      <Meta title={`${siteTitle} | 404: Not Found`} />
      <h1>Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  );
}

export const pageQuery = graphql`
  query NotFoundPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
